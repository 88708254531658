import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);



const Preview = (props) => {
    
    const {recipe_data : {comparison : {data = [], base_total_cost = Infinity, modified_total_cost = Infinity, savings = Infinity, base_total_moles = Infinity, modified_total_moles = Infinity, molesDiff_percentage = Infinity} = {}, prices = []} = {}} = props.data || {}
    const labels = data.map(d=>d.slot)
    const intensity_graph_data_base = data.map(d=>d.base_intensity)
    const intensity_graph_data_modified = data.map(d=>d.modified_intensity)
    const price_graph_data_base = data.map(d=>d.base_settlement_price)
    const price_graph_data_modified = data.map(d=>d.modified_settlement_price)
    const epex_graph_data = prices.map(d=>d.price)

    const options_intensity = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Intensity Comparison (Base Vs Modified)',
          },
        },
    };

    const options_price = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Price Comparison (Base Vs Modified)',
          },
        },
    };

    const options_epex = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'EPEX Price',
          },
        },
    };
      
    const data_intensity = {
        labels,
        datasets: [
          {
            label: 'Base Recipe',
            data: intensity_graph_data_base,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            label: 'Modified Recipe',
            data: intensity_graph_data_modified,
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      };

      const data_price = {
        labels,
        datasets: [
          {
            label: 'Base Recipe',
            data: price_graph_data_base,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            label: 'Modified Recipe',
            data: price_graph_data_modified,
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      };

      const data_epex = {
        labels,
        datasets: [
          {
            label: 'EPEX Prices',
            data: epex_graph_data,
            borderColor: 'rgb(204, 51, 102)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      };

    return(
        <>
        {
            (modified_total_moles !== Infinity && base_total_moles !== Infinity && molesDiff_percentage !== Infinity) ?
            <>
                <br/><br/>
                <div className='tbl_container'>
                    <table>
                        <tr>
                            <td className='savings-td'>Base Recipe Moles - Total</td>
                            <td className='savings-td'>: {base_total_moles.toFixed(4)}</td>
                        </tr>
                        <tr>
                            <td className='savings-td'>Modified Recipe Moles - Total</td>
                            <td className='savings-td'>: {modified_total_moles.toFixed(4)}</td>
                        </tr>
                        <tr>
                            <td className='savings-td'>Moles diff (percentage)</td>
                            <td className='savings-td'>: {molesDiff_percentage.toFixed(4)} %</td>
                        </tr>
                    </table>
                </div>
            </> : null
        }
        {
            (modified_total_cost !== Infinity && base_total_cost !== Infinity && savings !== Infinity) ?
            <>
                <br/><br/>
                <div className='tbl_container'>
                    <table>
                        <tr>
                            <td className='savings-td'>Base Recipe Cost - Total</td>
                            <td className='savings-td'>: £ {base_total_cost.toFixed(4)}</td>
                        </tr>
                        <tr>
                            <td className='savings-td'>Modified Recipe Cost - Total</td>
                            <td className='savings-td'>: £ {modified_total_cost.toFixed(4)}</td>
                        </tr>
                        <tr>
                            <td className='savings-td'>Cost Savings - Total</td>
                            <td className='savings-td'>: £ {savings.toFixed(4)}</td>
                        </tr>
                    </table>
                </div>
            </> : null
        }
        {
            intensity_graph_data_base.length > 0 && intensity_graph_data_modified.length > 0 ?
            <>
            <br/><br/>
            <div className='chart-line-wrapper'>
                <Line options={options_intensity} data={data_intensity} />
            </div></> : null
        }
        {
            epex_graph_data.length > 0 ?
            <>
            <br/><br/>
            <div className='chart-line-wrapper'>
                <Line options={options_epex} data={data_epex} />
            </div></> : null

        }
        {
            price_graph_data_base.length > 0  && price_graph_data_modified.length > 0?
            <>
            <br/><br/>
            <div className='chart-line-wrapper'>
                <Line options={options_price} data={data_price} />
            </div></> : null

        }
        </>
        
    )
}

export default Preview;